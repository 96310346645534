import React, { useEffect, useState, useRef } from 'react';
import QRCode from "react-qr-code";
import './App.css';
import clsx from 'clsx';

const axios = require('axios');


function App(props) {
  const [sessionID, setSessionID] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [qrData, setQRData] = useState('');
  const [qrSize, setQRSize] = useState(256);
  const [qrSource, setQRSource] = useState('');

  const sessionIDRef = useRef(null);

  useEffect(()=> {
    let url = window.location.href;
    let urlArr = url.split('?');
    if (urlArr[1]) {
      let queryString = urlArr[1];
      let queryStringArr = queryString.split('&')
      queryStringArr.forEach((query) => {
        let queryArr = query.split('=')
        switch (queryArr[0]) {
          case 'pub':
            // let base64data = btoa(queryArr[1] + ":");
            // setPublicKey(base64data);
            break;
          case 'qrData':
            let asciiQR = Buffer.from(queryArr[1], 'base64').toString('ascii')
            setQRData(asciiQR)
            break;
          case 'size':
            let targetSize = 256
            if (queryArr[1] > 256) targetSize = queryArr[1]
            setQRSize(targetSize)
            break;
          case 'source':
            setQRSource(queryArr[1])
            break;
          default:
            break;
        }
      })

      // Set event listener for pay event
        window.addEventListener('message', (message) => {
          if (!message || !message.data) return;
          const messageString = message.data + '';
          const messageArr = messageString.split(' ');
          if (window.parent != window) {
            if (messageArr[0] === 'pay') {
                // Process Card Tokenization Here with public key
                setSessionID(messageArr[1])
                // paymentMethod()
              }
          }
      });
      window.parent.postMessage('{"initialLoad": true}', '*')
    }
  },[])

  // const paymentMethod = async () => {
  //   setIsLoading(true)
  //   try {
  //     let sessionIDValue = sessionID || ((sessionIDRef && sessionIDRef.current && sessionIDRef.current.value) || "")

  //     window.parent.postMessage('{"paymentMethodId": "'+ (response && response.data && response.data.id ? response.data.id : "")+'", "sessionSecretIdentity":"'+sessionIDValue+'", "securityPin":"'+cardCvcValue+'"}', '*')
  //   } catch (err) {
  //     console.log('err -> ', err);
  //     setIsLoading(false)
  //   }
  //   setIsLoading(false)
  // }


  return (
    <main className={'qrContainer'}>
      <QRCode className={'qrCode'} size={qrSize} value={qrData} />
    </main>
  );
}


export default App